import type { HcState } from "./HcState";
import { getHcStorage, setHcStorage } from "./useHcStorage";

/**
 * Get the default start date of the session(?), which is apparently
 * tomorrow's date, according to `@hc-frontend/shared-assets`.
 */
function startDate(): string {
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const monthString = `${tomorrow.getMonth() + 1}`;
  const month = `0${monthString}`.substring(monthString.length - 1);
  return `${month}/${tomorrow.getDate()}/${tomorrow.getFullYear()}`;
}

/**
 * Set the default state of the `hc` object in `localStorage`, as originally defined in
 * `@hc-frontend/shared-assets`, and return it. If there's already an `hc` object in
 * `localStorage`, return that instead.
 */
export default function initialState(): HcState {
  // localStorage is not available in Node.js, e.g. when we are running `nx build dtc`,
  // so we should only touch it when we know we're running in a browser-like environment.
  const inBrowser = typeof window !== "undefined";
  /* c8 ignore next */
  const storedState = inBrowser ? getHcStorage() : null;
  if (storedState) return storedState;

  const state = {
    location: {
      zip_code: "",
      state_acronym: "",
      state: "",
      county: "",
      city: "",
    },
    cache: {
      locationByZip: {},
    },
    household: {
      size: 1,
      income: "",
      fpl: "",
    },
    contact: {
      first_name: "",
      last_name: "",
      email_address: "",
      address: "",
      phone_home: "",
      is_mobile: "",
    },
    members: [],
    life_event: 0,
    leadid: {
      universal_leadid: "",
      sended: false,
    },
    invoca_phone: {
      promo_number: "",
      promo_number_formatted: "",
    },
    sales_center_availability: {
      sunday: {
        to: "",
        from: "",
      },
      monday: {
        to: "",
        from: "",
      },
      tuesday: {
        to: "",
        from: "",
      },
      wednesday: {
        to: "",
        from: "",
      },
      thursday: {
        to: "",
        from: "",
      },
      friday: {
        to: "",
        from: "",
      },
      saturday: {
        to: "",
        from: "",
      },
    },
    utm: {
      utm_source: "",
      utm_campaign: "",
      utm_medium: "",
      utm_content: "",
      utm_term: "",
    },
    aid: "",
    pubid: 0,
    subid: 0,
    gclid: "",
    msclkid: "",
    irpid: "",
    ircid: "",
    bt_sid: "",
    source: "",
    pixel: "",
    url_version: "",
    landing_url: "",
    picreel_time: 0,
    healthcareSession: "",
    healthcareUserId: "",
    flowCatch: { show: false },
    agent_id: "",
    /* c8 ignore next */
    version: process.env.NEXT_PUBLIC_VERSION || "",
    period: "364",
    sdate: startDate(),
    lastUpdated: Date.now(),
  };

  if (inBrowser) setHcStorage(state);

  return state;
}
